@font-face {
    font-family: 'Gilroy';
    src: url('../src/resources/fonts/Gilroy-BlackItalic.eot');
    src: local('../src/resources/fonts/Gilroy Black Italic'), local('../src/resources/fonts/Gilroy-BlackItalic'),
        url('../src/resources/fonts/Gilroy-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('../src/resources/fonts/Gilroy-BlackItalic.woff') format('woff'),
        url('../src/resources/fonts/Gilroy-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}
@font-face {
    font-family: 'Gilroy';
    src: url('../src/resources/fonts/Gilroy-MediumItalic.eot');
    src: local('../src/resources/fonts/Gilroy Medium Italic'), local('../src/resources/fonts/Gilroy-MediumItalic'),
        url('../src/resources/fonts/Gilroy-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('../src/resources/fonts/Gilroy-MediumItalic.woff') format('woff'),
        url('../src/resources/fonts/Gilroy-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Gilroy';
    src: url('../src/resources/fonts/Gilroy-SemiBold.eot');
    src: local('../src/resources/fonts/Gilroy SemiBold'), local('../src/resources/fonts/Gilroy-SemiBold'),
        url('../src/resources/fonts/Gilroy-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('../src/resources/fonts/Gilroy-SemiBold.woff') format('woff'),
        url('../src/resources/fonts/Gilroy-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

body{
    margin:0px;
    padding: 0px;
    background-color: #121011;
    font-family: 'Gilroy';
}
*{
    box-sizing: border-box;
}
ul{
    list-style: none;
}
a{
    text-decoration: none;
}
button{
    outline: none;
    border: none;
}
input{
    outline: none;
    border: none;
}
.logo{
    padding-top: 50px;
}
.logo img{
    width: 200px;
    display: flex;
    margin: auto;
    align-items: center;

}
#main{
    width:100%;
    height:768px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('./resources/images/backgroundfull.png');
    z-index: 0;
}
#main::before{
    position: absolute;
    content: "";
    background:rgba(0, 0, 0, 0.7);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.header-heading{
    text-align: center;
	font-family: 'Gilroy';
	width: 900px;
	position: absolute;
    left: 50%;
	top: 70%;
	transform: translate(-50%,-55%);
}
.header-heading span{
    color: #A30403;
}
.header-heading .details{
    font-size: 23px;
    color: #c5c5c5;
    font-style: italic;
}
.header-heading h1{
	font-family:'Gilroy';
	font-size: 60px;
    line-height: 54px;
    font-weight: 900;
    font-style: italic;
    text-align: center;
	margin:0px;
	color:#ffffff;
    margin-bottom: 16px;
}
.header-heading h4{
	font-family:'Gilroy';
	font-size: 22px;
    font-style: italic;
    font-weight: 500;
	margin:0px;
	color:#ffffff;
    margin-bottom: 28px;
}

.list{
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-position: inside;
    margin-left: -40px;
    margin-bottom: 100px;
}

.list li{
    font-size: 20px;
    font-style: italic;
    font-weight: 500;
    margin-right: 20px;
    color: #fff;
}

.header-btns{
    display: flex;
    justify-content: center;
}
.header-btn{
	width:160px;
	height: 50px;
	display: flex;
	justify-content: center;
    align-items: center;
    font-size: 1rem;
    background-color: #A30403;
    box-shadow: 5px 10px 30px rgba(255, 2, 2, 0.336);
  
	color:#ffffff;
}
.header-btn:hover{
	background-color: transparent;
    transition: all ease 0.5s;
    color: #ffffff;
    border: 2px solid #A30403;
}

.action p {
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
    color: #fff;
}

#descrcards{
    width:100%;
    box-sizing: border-box;
    font-family:'Gilroy';
    font-style: italic;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px ;
    background-color: #121011;
}

#descrcards .emoji{
    width: 30px;
}

#descrcards h1{
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    justify-content: center;
    text-align: center;
    font-style: normal;

}
.a-container{
    display: flex;
    justify-content: center;
    align-content: center;


}
.a-box{
    background-color: #1A1A1A;
    width:165px;
    height: 200px;
    margin:10px;
    overflow: hidden;
    transition: 0.4s ease-in-out;
    position: relative;
    flex-wrap: wrap;
}
.a-b-img{
    width: 130px;
   height: 110px;
    margin: auto;

}
.a-b-img img{
    padding: 15px;
    width:100%;

}
.a-box:hover{
    border: 1px solid #A30403;
    box-shadow: 2px 2px 12px rgba(184, 0, 0, 0.445);
}
.a-b-text{
    width: 165px;
    padding: 0 15px;
    background-color:#1A1A1A;
    display:flex;
    justify-items: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}
.a-b-text h2{
    color: #fff;
    font-family:'Gilroy';
    font-weight: 500;
    font-style: italic;
    font-size: 16px;
}



#giftcards{
    width:100%;
    height: 700px;
    padding-top: 60px;
    box-sizing: border-box;
    font-family:'Gilroy';
    font-style: italic;
    overflow: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #121011;
}

#giftcards .emoji{
 width: 30px;
}

#giftcards .arrow{
    width:34px;
    height: 74px;
    position:relative;
    top: -30px;
    right: -100px;
    z-index: 2;

}
#giftcards h1{
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    justify-content: center;
    text-align: center;
    font-style: normal;

}
#giftcards h1 span{
    color: #A30403;
    font-size: 26px!important;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 30px!important;
}

.gift-container{
    display: flex;
    justify-content: center;
    align-content: center;
width: 100%;
padding: 30px;
margin-top: -80px;

}

.swiper{
    padding: 2rem;
}
.swiper .res-slide{
    min-width: 340px;
    max-width: 350px;
    min-height: 410px;
}


.res-slide:nth-child(1){
    background: center / cover no-repeat url(../src/resources/images/massage.png);
}
.res-slide:nth-child(2){
    background: center / cover no-repeat url(../src/resources/images/gift2.png);
}
.res-slide:nth-child(3){
    background: center / cover no-repeat url(../src/resources/images/month.jpg);
}
.res-slide::before{
    position: absolute;
    content: "";
    background: rgba(0, 0, 0, .5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.res-slide{
    display: flex;
    justify-content: center;
}

.res-slide h1{
    font-size: 36px!important;
    margin-top: 80px;
    padding: 0 10px;

}

.res-slide a{
    width: 200px;
    height: 50px;
    display: flex;
	justify-content: center;
    align-items: center;
    font-size:16px;
    background-color: #A30403;
    box-shadow: 5px 10px 30px rgba(255, 2, 2, 0.336);
    position: absolute;
bottom: 60px;
left: 75px;
	color:#ffffff;
}

.res-slide a:hover{
	background-color: transparent;
    transition: all ease 0.5s;
    color: #ffffff;
    border: 2px solid #FF1414;
}

.swiper-pagination-bullet{
    background-color: #f70000!important;
}

#formbox::before{
    position: absolute;
    content: "";
    background: rgba(0, 0, 0, .7);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

}
#formbox{
    background-position: center;
    background-image: url(../src/resources/images/bg-form.png);
    width:100%;
    height: 480px;
    padding: 30px;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 30px 0;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
}


#formbox h1{
    color: #fff;
    font-size: 20px;
    text-align: center;
    margin-bottom: 130px;
    margin-top: 60px;
}
#formbox .form{
    width:900px;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}
#formbox .form input, 
#formbox .form textarea{
    width:100%;
    height:50px;
    margin:5px 20px;
    padding: 10px;
    border: none;
    outline: none;
    background-color: transparent;
    color: #ffffff;
    border-bottom: 2px solid #ffffff;
}
#formbox .form textarea{
    height: 150px;
}
#formbox .form input[type="submit"]{
    height: 45px;
    margin-top: 20px;
    background: linear-gradient(90deg, #FF1414 20%,#f70000);
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
    font-style: italic;
    font-size: 12px;
    box-shadow: 5px 10px 30px rgba(255, 2, 2, 0.336);
    border: none!important;
}

.footer{
    width:100%;
    height: 480px;
    padding: 30px;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: auto;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    text-align: center;
    color: #fff;
    background-image: url(../src/resources/images/footer3.png);
}
.footer h1{
    font-style: italic;
    font-weight: 900;
}
.footer .phonenumber{
    margin: 20px;
}
.footer .phonenumber img{
    margin: 20px;
}

.footer a{
    color: #fff;
    display: flex;
    flex-direction: column;

}
.footer::before{
    position: absolute;
    content: "";
    background: rgba(0, 0, 0, .6);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}
.f-container{
    width: 100%;
    position: relative;
}

.copyright{
    background-color: #fff;
    color: #1A1A1A;
    width: 100%;
    height: 74px;
}
.copyright .details{
    font-size: 14px;
   text-align: center;
    padding-top:25px;
}



@media(max-width:620px){
    #formbox .form{
        width:90%;
    }
}

@media(max-width:1190px){
	
	.header-heading{
		left: 50%;
		top: 65%;
        transform: translate(-50%,-50%);
        text-align: center;
    }
    .header-heading h1{
        font-size: 60px;
        justify-items: center;
        line-height: 54px;
        margin-bottom: 26px;
    }
    .header-heading h4{
        font-size: 22px;
        line-height: 26px;
        font-style: italic;
        font-weight: 500;
    }
    .header-heading .details{
        font-size: .5rem;
    }
    
    #descrcards{
		height:auto;
    }
    .a-container{
		flex-wrap: wrap;
	}

    .a-b-img img{
		object-fit:contain;
    }
    
    

}

@media(max-width:970px){
	#main{
		height: 730px;
        background-image: url(../src/resources/images/background.png);
	}
	.header-heading{
		left: 50%;
		top: 70%;
		transform: translate(-50%,-50%);
    }
    .list{
       display: block;
       margin-bottom: 30px;

    }
    .list li{
        font-size: 20px;
    }
    
    .header-btn{
    
        margin-top: 20px;
    }
    
	
    #descrcards{
        margin-bottom: 50px;
    }
}
@media(max-width:600px){
	.header-heading{
		width:70%;
    }
    
}

@media(max-width:1100px){.menu-icon{
    display:block;}
       
        .logo{
            padding-top: 25px;
        }
        .logo img{
            width:150px;
            margin: auto;
            display: flex;
            align-items: center;
        }
        .header-btns{
            margin: 0;
        }
        
    }
        @media(max-width:416px){
            .a-box{
                width: 165px;
                height:200px;
            }
            #formbox::before{
                position: absolute;
                content: "";
                background: rgba(0, 0, 0, .7);   
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: -1;  
            }
            #formbox{
                background-image: url(../src/resources/images/form.png);
             width: 350px;
             margin: 30px auto;
              margin-bottom: 30px;
            }
            
            
            #giftcards{
                height: 550px;
                padding-top: 0;
            }
            #descrcards h1{
                font-size: 16px;
                text-transform: uppercase;
                width: 80%;
                margin-bottom: 30px;

            }
            #formbox .form{
                flex-direction: column;
            }
        }